import React from "react";
import './index.css';
import homebg from '../../../assets/images/homebg.webp';

const ContentOne = () => {

    return <div id="home">
        <div
            className="bg-box"
            style={{
                backgroundImage: `url(${homebg})`
            }}
        >
            <div>{"Creative AI Platform"}</div>
            <div>{"for next generation developers"}</div>
        </div>
    </div>
}

export default ContentOne;