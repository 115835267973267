import React from "react";
import './index.css';

const MyFooter = () => {
    return <div className="my-footer" id="contactus">
        <div>
            <div>{"Tensors Lab © 2025"}</div>
            <div>{"All rights reserved."}</div>
        </div>
        <a href="mailto:info@tensorslab.com" className="contact" style={{ color: 'rgba(255, 255, 255, 1)' }}>
            {"Contact Us"}
        </a>
    </div>
}

export default MyFooter;