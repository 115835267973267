import React, { useEffect } from "react";
import ContentOne from './contentOne';
import ContentTwo from './contentTwo';
import ContentThree from './contentThree';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div>
        <ContentOne/>
        <ContentTwo/>
        <ContentThree/>
    </div>
}

export default Home;