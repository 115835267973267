import React, { useState, useEffect } from 'react';
import { ConfigProvider, Flex, Layout } from 'antd';
import './App.css';
import { theme } from './theme';
import MyHeader from './components/header';
import MyFooter from './components/footer';
import RouterView from './routes';
import { TasksProvider } from './context';

const { Header, Footer, Content } = Layout;

function App() {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 1) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (<ConfigProvider theme={theme}>
        <TasksProvider>
            <Flex gap="middle" wrap>
                <Layout className='layout-style'>
                    <Header className='header-style' style={{ background: 'rgba(0, 0, 0, 1)' }}><MyHeader isScrolled={isScrolled} /></Header>
                    <Content className='content-style'><RouterView></RouterView></Content>
                    <Footer className='footer-style'><MyFooter /></Footer>
                </Layout>
            </Flex>
        </TasksProvider>
    </ConfigProvider>);
}

export default App;
