import React from "react";
import './index.css';
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import home_tensorai from '../../../assets/images/home_tensorai.png';
import home_tensorbee from '../../../assets/images/home_tensorbee.png';
import oval from '../../../assets/images/oval.png';

const ContentTwo = () => {
    const navigate = useNavigate();
    const goToClick = (key) => {
        switch (key) {
            case 1:
                navigate("/tensorai");
                break;
            case 2:
                window.open("https://www.tensorbee.com/", '_blank');
                break;
            case 3:
                break;
            default:
                console.log("什么也没有！");
        }
    }

    return <div id="products">
        <div className="synopsis">
            <div className="banner">
                <div>
                    <div className="lyo" style={{ backgroundImage: `url(${oval})` }}>
                        <div className="img"><img src={home_tensorai} alt="" /></div>
                        <div className="action">
                            <div className="text">
                                <div className="title">{"TensorAI"}</div>
                                <span className="content">
                                    {"Vision, Video, MLLM — provide leading generative AI API services ,immediately unlock all your creative ideas with TensorAI."}
                                </span>
                            </div>
                            <Button className="but" onClick={() => goToClick(1)}>{"Go"}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner">
                <div>
                    <div className="lyo" style={{ backgroundImage: `url(${oval})` }}>
                        <div className="action">
                            <div className="text">
                                <div className="title">{"TensorBee"}</div>
                                <span className="content">
                                    {"Powerful AI node and matrix server — privately deploy you own AI services, using our out-of-the-box AI Apps with ease."}
                                </span>
                            </div>
                            <Button className="but" onClick={() => goToClick(2)}>{"Go"}</Button>
                        </div>
                        <div className="img"><img src={home_tensorbee} alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="information">
            <div className="title">{'Everybody in the world is now a programmer.'}</div>
            <div className="introduce">{'With our products, we believe more developers could easily setup & share their AI service in the future.'}</div>
            <div className="content">
                <div className="item">{"200K API access a day."}</div>
                <div className="item">{"20M+ images generated."}</div>
                <div className="item">{"2M+ video created."}</div>
            </div>
            <div className="content content-two">
                <div className="item">{"10+ featured AI APIs."}</div>
                <div className="item">{"1000+ early bird developers."}</div>
                <div className="item">{"Numbers refreshing."}</div>
            </div>
        </div>
        <div className="tags">
            <div className="title">{"Out-of-Box, Flexible, Cost Effective "}</div>
            <div className="content">{"Start here from sending us a request mail, online API trial under construction."}</div>
        </div>
    </div>
}

export default ContentTwo;