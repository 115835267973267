import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import TensorAI from '../pages/tensorAi';

export default function RouterView() {

    return (
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/tensorai" element={<TensorAI/>} />
        </Routes>
    );
}