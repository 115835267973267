import React, { useState, useEffect } from "react";
import './index.css';
import { Anchor, ConfigProvider, Drawer, Button } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useTasks } from '../../context';
import logo from '../../assets/images/logo.png';

const MyHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const tasks = useTasks();
    const [isTensorAI, setIsTensorAI] = useState(false);

    const [open, setOpen] = useState(false);
    const FloatButClick = (value) => {
        setOpen(value)
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleClick = (e, link) => {
        e.preventDefault();
        setOpen(false);
    };
    const goToHome = () => {
        navigate("/");
    };

    useEffect(() => {
        if (location.pathname === '/tensorai') {
            setIsTensorAI(true);
        } else {
            setIsTensorAI(false);
        }
    }, [location]);

    return <div className="my-header">
        <div className="logo" onClick={goToHome}>
            <img src={logo} alt="" />
            {!tasks.isMobileWeb ? <div>{"Tensors Lab"}</div> : <></>}
        </div>
        <div className="anchor">
            {
                !tasks.isMobileWeb && !isTensorAI ?
                <ConfigProvider
                    theme={{
                        components: {
                            Anchor: {
                                colorText: 'rgba(255, 255, 255, 1)',
                                linkActiveColor: 'rgba(255, 255, 255, 1)',
                            },
                        },
                    }}
                >
                    <Anchor
                        direction="horizontal"
                        items={[
                            {
                                key: 'home',
                                href: '#home',
                                title: 'Home',
                            },
                            {
                                key: 'products',
                                href: '#products',
                                title: 'Products',
                            },
                            // {
                            //     key: 'contactus',
                            //     href: '#contactus',
                            //     title: 'Contact Us',
                            // },
                        ]}
                        style={{
                            '--anchor-ink-color': 'rgba(255, 255, 255, 1)',
                            '--active-color': 'rgba(255, 255, 255, 1)',
                        }}
                    />
                </ConfigProvider> :
                <div>
                    {
                        !isTensorAI ?
                        <Button
                            className="fixed-but"
                            shape="circle"
                            icon={<MenuOutlined style={{ fontSize: '24px' }}/>}
                            type="text"
                            onClick={() => FloatButClick(true)}
                        /> : <></>
                    }
                    <Drawer
                        title={
                            <Button
                                className="close-but"
                                shape="circle"
                                icon={<CloseOutlined style={{ fontSize: '24px' }}/>}
                                type="text"
                                onClick={() => FloatButClick(false)}
                            />
                        }
                        placement="top"
                        closable={false}
                        onClose={onClose}
                        open={open}
                        key='top_placement'
                        rootClassName="my-drawer"
                    >
                        <ConfigProvider
                            theme={{
                                components: {
                                    Anchor: {
                                        ink: {
                                            backgroundColor: 'rgba(252, 223, 222, 1)',
                                            width:'5px',
                                            height: '46px',
                                            top: '23px',
                                        },
                                    },
                                },
                            }}
                        >
                            <Anchor
                                direction="vertical"
                                affix={false}
                                onClick={handleClick}
                                items={[
                                    {
                                        key: 'home',
                                        href: '#home',
                                        title: 'Home',
                                    },
                                    {
                                        key: 'products',
                                        href: '#products',
                                        title: 'Products',
                                    },
                                    // {
                                    //     key: 'contactus',
                                    //     href: '#contactus',
                                    //     title: 'Contact Us',
                                    // },
                                ]}
                                />
                        </ConfigProvider>
                    </Drawer>
                </div>
            }
        </div>
    </div>
}

export default MyHeader;