import React, { useEffect } from "react";
import './index.css';
import { useTasks } from '../../context';
import tensor_ai_bg from '../../assets/images/tensor_ai_bg.png';
import tensor_ai_header from '../../assets/images/tensor_ai_header.png';
import tensor_ai_card_1 from '../../assets/images/tensor_ai_card_1.png';
import tensor_ai_card_2 from '../../assets/images/tensor_ai_card_2.png';
import tensor_ai_card_3 from '../../assets/images/tensor_ai_card_3.png';
import tensor_ai_card_4 from '../../assets/images/tensor_ai_card_4.jpg';
import tensor_ai_card_5 from '../../assets/images/tensor_ai_card_5.png';
import tensor_ai_card_6 from '../../assets/images/tensor_ai_card_6.png';

const TensorAI = () => {
    const tasks = useTasks();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div className="tensor-ai">
        <div className="header">
            <div
                className="header-bg"
                style={{
                    backgroundImage: tasks.isMobileWeb ? 'none' : `url(${tensor_ai_bg})`
                }}
            >
                <div className="lyo">
                    <div className="text">
                        <div className="title">{"TensorAI"}</div>
                        <span className="content">
                            {"Immediately connect your app to our fantastic TensorAI."}
                        </span>
                    </div>
                    <div className="img"><img src={tensor_ai_header} alt="" /></div>
                </div>
            </div>
        </div>
        <div className="info">
            <div className="title">{"We provides APIs in competitive price! "}</div>
            <div className="content">
                {"• Standard and customized AI functions and workflows, with a rich selection."}
                <br /><br />
                {"• Deeply optimized pipeline on highend GPU and TPU accelarators, best performance."}
                <br /><br />
                <br /><br />
                {"Try TensorAI on our partners' website, "}
                <a href="https://goodapp.ai" target="_blank" rel="noopener noreferrer">
                    {"goodapp.ai"}
                </a>
            </div>
        </div>
        <div className="body">
            <div className="card">
                <div className="img">
                    <div>{"Video"}</div>
                    <img src={tensor_ai_card_1} alt="" />
                </div>
                <div className="content">
                    <div>{"Video"}</div>
                    <div>{"Create dynamic videos with ease using our AI-powered video generator. Generate videos directly from text or combine text with images for more personalized content. Perfect for storytelling, marketing, or creative projects."}</div>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <div>{"VitrualID"}</div>
                    <img src={tensor_ai_card_3} alt="" />
                </div>
                <div className="content">
                    <div>{"VirtualID"}</div>
                    <div>{"Create lifelike avatars with our realistic portrait generator, blending authenticity with artistic flair. Customize features to reflect your unique personality, perfect for professional profiles or personal branding."}</div>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <div>{"StylerID"}</div>
                    <img src={tensor_ai_card_2} alt="" />
                </div>
                <div className="content">
                    <div>{"StylerID"}</div>
                    <div>{"Unleash your creativity with our unique avatar generator! Choose from vibrant artistic styles to create a personalized avatar that truly represents you. Stand out on social media with a custom avatar as unique as you are!"}</div>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <div>{"Flux"}</div>
                    <img src={tensor_ai_card_6} alt="" />
                </div>
                <div className="content">
                    <div>{"Standard Flux"}</div>
                    <div>{"Experience the next-gen of image generation. Create highly realistic images with stunning detail and reduced hand distortion. Flux delivers superior quality, making your generated visuals more lifelike and accurate than ever before."}</div>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <div>{"SDXL"}</div>
                    <img src={tensor_ai_card_5} alt="" />
                </div>
                <div className="content">
                    <div>{"Standard SDXL"}</div>
                    <div>{"SDXL is the most popular image generation model, offering exceptional control over poses, facial similarity, and a variety of artistic styles, enabling the creation of realistic and highly personalized visuals."}</div>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <div>{"Eraser"}</div>
                    <img src={tensor_ai_card_4} alt="" />
                </div>
                <div className="content">
                    <div>{"Eraser"}</div>
                    <div>{"Effortlessly erase unwanted elements with our powerful eraser tool. Whether it's removing content or background from an image or specific parts of a video, our tool makes it easy to refine your visuals and focus on what matters."}</div>
                </div>
            </div>
        </div>
    </div>
};

export default TensorAI;