// src/theme.js
export const theme = {
    token: {
        // Seed Token，影响范围大
        // colorPrimary: '#00b96b',
        borderRadius: 16,
        bodyBg: '#fff',
        // 派生变量，影响范围小
        colorBgContainer: '#fff',
    },
    components: {
        Layout: {
            headerBg: '#fff',
        },
    },
};