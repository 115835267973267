import { createContext, useContext, useReducer, useEffect } from 'react';

const TasksContext = createContext(null);

const TasksDispatchContext = createContext(null);

export function TasksProvider({ children }) {
    const [tasks, dispatch] = useReducer(
        tasksReducer,
        initialTasks
    );
    useEffect(() => {
        const handleResize = () => {
            dispatch({
                type: 'set_is_mobile_web',
                data: window.innerWidth < 768
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <TasksContext.Provider value={tasks}>
            <TasksDispatchContext.Provider value={dispatch}>
                {children}
            </TasksDispatchContext.Provider>
        </TasksContext.Provider>
    );
}

export function useTasks() {
    return useContext(TasksContext);
}

export function useTasksDispatch() {
    return useContext(TasksDispatchContext);
}

function tasksReducer(tasks, action) {
    switch (action.type) {
        case 'set_is_mobile_web': {
            const newTask = {...tasks};
            newTask.isMobileWeb = action.data;
            return newTask;
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const initialTasks = {
    isMobileWeb: window.innerWidth < 768
};